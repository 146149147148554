<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1>
            <div>
              Информация<br />о работе системы
              <v-btn outlined @click="exportXlsx()">скачать</v-btn>
              <LoaderDialog :show="showLoaderDialog"></LoaderDialog>
            </div>
          </h1>

          <div class="sysInfo">
            <div>
              <v-row>
                <v-select
                  :items="getListOfUsers()"
                  style="margin-right: 20px"
                  label="Имя пользователя"
                  v-model="userNameFilter"
                />

                <v-select
                  :items="getListOfEvents()"
                  style="margin-right: 20px"
                  label="Событие"
                  v-model="eventFilter"
                />

                <v-text-field
                  style="margin-right: 20px"
                  label="Дополнительная информация"
                  v-model="messageFilter"
                />
              </v-row>
            </div>
            <table
              class="anketsRegister-table"
              style="width: 100%; table-layout: fixed"
            >
              <thead>
                <tr>
                  <th colspan="4">Дата и время</th>
                  <th colspan="4">Имя пользователя</th>
                  <th colspan="4">Событие</th>
                  <th colspan="7">Дополнительная информация</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="l in filterLogEntries()" :key="'asdffg' + l.id">
                  <td colspan="4">
                    {{ formatDateTime(l.localDateTime) }}
                  </td>
                  <td colspan="4" style="white-space: normal">
                    {{ l.userName }}
                  </td>
                  <td colspan="4">
                    {{ l.event }}
                  </td>
                  <td colspan="7">
                    {{ l.message }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="errorText != null"
          class="error"
          style="position: fixed; bottom: 0"
        >
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height: 1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style scoped lang="scss">
</style>

<script>
import LoaderDialog from "../elements/LoaderDialog";

export default {
  props: {},
  components: { LoaderDialog },
  data() {
    return {
      logEntries: [],
      userNameFilter: null,
      eventFilter: null,
      messageFilter: null,
      showLoaderDialog: false,
      apiLoaded: false,
      errorText: null,
    };
  },
  methods: {
    async loadLogs() {
      let req = await this.$getApi("/logs/getAll");
      if (req.ok) {
        this.logEntries = req.payload.reverse();
      } else {
        this.errorText =
          "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    filterLogEntries() {
      return this.logEntries.filter((v) => {
        if (
          this.userNameFilter != null &&
          this.userNameFilter != "" &&
          (v.userName == null || !v.userName.includes(this.userNameFilter))
        )
          return false;
        if (
          this.eventFilter != null &&
          this.eventFilter != "" &&
          (v.event == null || !v.event.includes(this.eventFilter))
        )
          return false;
        if (
          this.messageFilter != null &&
          this.messageFilter != "" &&
          (v.message == null || !v.message.includes(this.messageFilter))
        )
          return false;
        return true;
      });
    },

    getListOfUsers() {
      let res = [];
      for (let e of this.logEntries) {
        if (!res.includes(e.userName)) res.push(e.userName);
      }
      return res;
    },

    getListOfEvents() {
      let res = [];
      for (let e of this.logEntries) {
        if (!res.includes(e.event)) res.push(e.event);
      }
      return res;
    },

    formatDateTime(date) {
      if (date == null) {
        return "";
      }
      let mdate = new Date(date);
      let options = {
        timeZone: "Europe/Moscow",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return mdate.toLocaleDateString("ru-RU", options);
    },

    async exportXlsx() {
      this.showLoaderDialog = true;
      let query = {
        headerCells: [
          {
            caption: "Дата и время",
            width: 5000,
          },
          {
            caption: "Пользователь",
            width: 5000,
          },
          {
            caption: "Событие",
            width: 7000,
          },
          {
            caption: "Сообщение",
            width: 7000,
          },
        ],
        rows: [],
      };

      for (let v of this.filterLogEntries()) {
        query.rows.push([
          { cellDataType: "STRING", value: v.localDateTime },
          { cellDataType: "STRING", value: v.userName },
          {
            cellDataType: "STRING",
            value: v.event,
          },
          { cellDataType: "STRING", value: v.message },
        ]);
      }
      await this.$downloadApi("ИС_Мониторинга_СЗН_Логи.xlsx", "/export/xlsx", query, true);
      this.showLoaderDialog = false;
    },
  },
  async beforeMount() {
    await this.loadLogs();
    this.apiLoaded = true;
  },
};
</script>
